<template>
  <div id="app">
    <div class="index-header">
      <div class="bg">
        <div class="index-avatar">
          <van-image :src="avatar" />
          <van-image :src="require('../../assets/user-circle.png')" />
        </div>
        <div class="index-font">
          <div class="name">{{ name }}</div>
          <div class="job">{{ jName }}</div>
        </div>
        <div class="ss" @click="qrCodeIndex" :hidden="!finishWx">
          <van-image :src="require('../../assets/ss.png')" />
        </div>
      </div>
    </div>
    <div class="index-wrap index-nav">
      <div class="button-eq item" @click="goUrls('/asset/asset')">
        <span>资产查询</span>
      </div>
      <div class="button-parts item" @click="goUrls('/parts/parts')">
        <span>备件查询</span>
      </div>
    </div>
    <div class="index-wrap index-container">
      <div class="index-header-box">
        <img src="../../assets/index-container-header.png" alt="" class="fl img">
        <span class="fl span">工作台</span>
      </div>
      <div class="index-grid-box">
        <van-grid :column-num="columnNumber">
          <van-grid-item @click="goUrls('/workorder/pm1')">
            <img src="../../assets/rcdj.png" alt="">
            <span>日常点检</span>
          </van-grid-item>
          <van-grid-item @click="goUrls('/workorder/pm2')" :hidden = "scczb">
            <img src="../../assets/zqxj.png" alt="">
            <span>周期巡检</span>
          </van-grid-item>
          <van-grid-item @click="goUrls('/workorder/pm3')" :hidden = "scczb">
            <img src="../../assets/whjd.png" alt="">
            <span>完好鉴定</span>
          </van-grid-item>
          <van-grid-item @click="goUrls('/workorder/pm4')" :hidden = "scczb">
            <img src="../../assets/jyrh.png" alt="">
            <span>加油润滑</span>
          </van-grid-item>
          <van-grid-item @click="goUrls('/workorder/pm5')" :hidden = "scczb">
            <img src="../../assets/dqby.png" alt="">
            <span>定期保养</span>
          </van-grid-item>
          <van-grid-item @click="goUrls('/workorder/pm6')" :hidden = "scczb">
            <img src="../../assets/yfwh.png" alt="">
            <span>预防维修</span>
          </van-grid-item>
          <van-grid-item @click="goUrls('/workorder/pm7')" :hidden = "scczb">
            <img src="../../assets/jdjc.png" alt="">
            <span>精度检测</span>
          </van-grid-item>
          <van-grid-item @click="goUrls('/workorder/pm8')" :hidden = "scczb">
            <img src="../../assets/qxhy.png" alt="">
            <span>清洗换油</span>
          </van-grid-item>
          <van-grid-item @click="goUrls('/workorder/pm9')" :hidden = "scczb">
            <img src="../../assets/jrwb.png" alt="">
            <span>计划维修</span>
          </van-grid-item>
          <van-grid-item @click="goUrls('/project/apply')">
            <img src="../../assets/gzbx.png" alt="">
            <span>故障报修</span>
          </van-grid-item>
          <van-grid-item @click="goUrls('/workorder/cm')" :hidden = "scczb">
            <img src="../../assets/gzwx.png" alt="">
            <span>故障维修</span>
          </van-grid-item>
          <van-grid-item @click="goRepair" :hidden = "scczb">
            <img src="../../assets/wwgd.png" alt="">
            <span>补录保修</span>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <div class="index-wrap index-kpi" style="display: none;">
      <div class="index-header-box">
        <img src="../../assets/index-kpi-header.png.png" alt="" class="fl img">
        <span class="fl span">工单统计</span>
      </div>
      <div class="index-kpi-box">
        <div class="item">
          <div class="top">
            <div class="fl">日常点检</div>
            <div class="fr">已完成50%</div>
          </div>
          <van-progress :stroke-width="8" color="linear-gradient(to right, #1965FE, #1B8DEB)" :percentage="25" :show-pivot="false" />
        </div>
        <div class="item" :hidden = "scczb">
          <div class="top">
            <div class="fl">周期巡检</div>
            <div class="fr">已完成50%</div>
          </div>
          <van-progress :stroke-width="8" color="linear-gradient(to right, #FF7343, #FFAD43)" :percentage="65" :show-pivot="false" />
        </div>
        <div class="item" :hidden = "scczb">
          <div class="top">
            <div class="fl">完好鉴定</div>
            <div class="fr">已完成50%</div>
          </div>
          <van-progress :stroke-width="8" color="linear-gradient(to right, #1965FE, #1B8DEB)" :percentage="78" :show-pivot="false" />
        </div>
        <div class="item" :hidden = "scczb">
          <div class="top">
            <div class="fl">加油润滑</div>
            <div class="fr">已完成50%</div>
          </div>
          <van-progress :stroke-width="8" color="linear-gradient(to right, #1965FE, #1B8DEB)" :percentage="37" :show-pivot="false" />
        </div>
        <div class="item" :hidden = "scczb">
          <div class="top">
            <div class="fl">定期保养</div>
            <div class="fr">已完成50%</div>
          </div>
          <van-progress :stroke-width="8" color="linear-gradient(to right, #39D5D2, #39D5AE)" :percentage="82" :show-pivot="false" />
        </div>
        <div class="item" :hidden = "scczb">
          <div class="top">
            <div class="fl">预防维护</div>
            <div class="fr">已完成50%</div>
          </div>
          <van-progress :stroke-width="8" color="linear-gradient(to right, #39D5D2, #39D5AF)" :percentage="100" :show-pivot="false" />
        </div>
        <div class="item" :hidden = "scczb">
          <div class="top">
            <div class="fl">精度检测</div>
            <div class="fr">已完成50%</div>
          </div>
          <van-progress :stroke-width="8" color="linear-gradient(to right, #FDC125, #FDEB25)" :percentage="44" :show-pivot="false" />
        </div>
        <div class="item" :hidden = "scczb">
          <div class="top">
            <div class="fl">清洗换油</div>
            <div class="fr">已完成50%</div>
          </div>
          <van-progress :stroke-width="8" color="linear-gradient(to right, #FDC225, #FDEB25)" :percentage="88" :show-pivot="false" />
        </div>
        <div class="item" :hidden = "scczb">
          <div class="top">
            <div class="fl">计划维修</div>
            <div class="fr">已完成50%</div>
          </div>
          <van-progress :stroke-width="8" color="linear-gradient(to right, #FF7443, #FFAC43)" :percentage="36" :show-pivot="false" />
        </div>
        <div class="item" :hidden = "scczb">
          <div class="top">
            <div class="fl">故障维修</div>
            <div class="fr">已完成50%</div>
          </div>
          <van-progress :stroke-width="8" color="linear-gradient(to right, #FF7543, #FFAB43)" :percentage="87" :show-pivot="false" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WxApi from '@/api/wx'
import wx from 'weixin-js-sdk'
import SELECT from '@/api/select'
import getUrlTool from '@/utils/getUrlParam'

import { mapGetters } from 'vuex'
import { Notify, Toast } from 'vant'

export default {
  inject: ['goUrl'],
  data() {
    return {
      finishWx: false,
      columnNumber: 3,
      // 生产操作班,生产操作班只看几个菜单, 菜单变成两列
      scczb: false
    }
  },
  computed: {
    ...mapGetters(['name', 'avatar', 'userId', 'cName', 'jName'])
  },
  created() {
    // this.getCode()
    this.getConfig()
    this.index()
  },
  methods: {
    getCode() {
      const appId = 'wxe5681186adb75661'
      const code = getUrlTool.getUrlParam('code')
      const local = window.location.href
      if (code == null || code === '') {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        this.getOpenId(code) //把code传给后台获取用户信息
      }
    },
    getOpenId(codes) {
      const data = { code: codes }
      WxApi.openIds(data).then(res => {
        console.log(res, '123')
      })
    },
    onLoad() {
      // 我又不想改名又想用一个onload哈哈哈
      this.index()
    },
    index() {
      if (this.cName === '生产操作班') {
        this.scczb = true
        this.columnNumber = 2
      }
    },
    goDetail(id, category) {
      if (category === 0) {
        this.url = `/order/pm_detail/${id}`
      } else if (category === 1) {
        this.url = `/order/cm_detail/${id}`
      } else if (category === 2) {
        this.url = `/order/parts_apply_detail/${id}`
      }
      this.goUrl(this.url)
    },
    qrCodeIndex() {
      const that = this
      this.checkJsApi("scanQRCode").then((rel) => {
        if (rel) {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              // let result = res.resultStr.split(',')[1]; // 当needResult 为 1 时，扫码返回的结果
              const code = res.resultStr
              const kinds = code.split('|')
              if (kinds.length !== 2) {
                alert('此二维码格式不对，请联系管理员！！')
              }
              if (kinds[0] === 'e') {
                // 设备
                SELECT.eqList({coding: kinds[1], exact: 1}).then(eqRes => {
                  if (eqRes.data.error_code) {
                    Notify({
                      type: 'danger',
                      message: eqRes.data.message
                    })
                  } else {
                    const eq = eqRes.data.data[0]
                    that.goUrl(`/asset/equipment_detail/${eq.id}`)
                  }
                })
              } else {
                alert('这不是设备的二维码')
              }
            }
          })
        }
      })
    },
    goUrls(url) {
      this.goUrl(url)
    },
    goRepair() {
      this.$router.push({
        path: '/project/apply_add',
        query: {
          repair: '1'
        }
      })
    },
    // wechat init
    getConfig() {
      const self = this
      let url = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.localStorage.getItem('scanUrl');

      WxApi.wxJSTicket({ url: url }).then((res) => {
        const data = res.data;
        wx.config({
          beta: true,
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名
          jsApiList: ['chooseImage','uploadImage','getLocalImgData','scanQRCode','checkJsApi'] // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          console.log("成功")
          self.finishWx = true
        })
        wx.error(function (err) {
          console.log(err)
        })
      })
    },
    checkJsApi(api) {
      //检测api是否可用
      return new Promise((rel, err) => {
        wx.checkJsApi({
          jsApiList: [api], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            if (res.checkResult && res.checkResult.scanQRCode == false) {
              this.$toast("微信版本较低，请升级后重试！")
              Toast.fail('微信版本较低，请升级后重试！')
              err("版本低")
            } else {
              rel(true)
            }
          }
        })
      })
    },
  }
}
</script>

<style scoped>
.bg{
  width: 10rem;
  height: 2.6rem;
  background-image: url('../../assets/index-top-bg.png');
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 0.52rem 0.32rem 0;
}
.bg .index-avatar{
  width: 1rem;
  height: 1rem;
  float: left;
  margin-right: .2rem;
  border-radius: 50%;
  overflow: hidden;
}
.bg .index-avatar img{
  width: 100%;
}
.bg .index-font{
  float: left;
  text-align: left;
  color: #fff;
}
.bg .index-font .name{
  font-size: .4rem;
}
.bg .index-font .job{
  font-size: .3rem;
}
.bg .ss{
  float: right;
  width: .8rem;
}
.bg .ss img{
  width: 100%;
}
.index-wrap{
  width: 351px;
  background-color: #fff;
  border-radius: 5px;
  margin:0 auto;
}
.index-header-box{
  overflow: hidden;
  padding-top: 9px;
}
.index-header-box .img{
  width: 17px;
  height: 17px;
  display: block;
  margin: 3px 9px 0 10px;
}
.index-header-box .span{
  font-size: 16px;
}
.index-nav{
  height: 2.6rem;
  box-sizing: border-box;
  margin-top: -0.6rem;
  padding: 10px 17px 0;
}
.index-nav .item{
  width: 151px;
  height: 70px;
  box-sizing: border-box;
  background-size: 100% 100%;
  position: relative;
}
.index-nav .item span{
  font-size: 0.47rem;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0px 4px 9px rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 26px;
  left: 9px;
}
.index-nav .item.button-eq{
  background-image: url('../../assets/index-search-eq.png');
  float: left;
}
.index-nav .item.button-parts{
  background-image: url('../../assets/index-search-parts.png');
  float: right;
}
.index-container{
  margin-top: 5px;
}
.index-grid-box img{
  width: 30px;
  height: 30px;
}
.index-grid-box span{
  font-size: 16px;
  margin-top:7px;
}
.index-kpi{
  height: 480px;
  margin: 5px auto 10px;
}
.index-kpi-box{
  width: 329px;
  margin: 0 auto;
}
.index-kpi-box .item .top{
  font-size: 16px;
  overflow: hidden;
  clear: both;
  margin: 10px 0 1px;
}
.index-kpi-box .item .top .fr{
  color: #447AFE;
}
</style>

import request from "@/utils/request"

export default {
    wxJSTicket(params) {
        return request({
            url: '/v1/wxJSTicket',
            method: 'get',
            params
        })
    },
    openIds(data) {
        return request({
            url: '/v1/openIds',
            method: 'post',
            data
        })
    },
    sendTo(params) {
        return request({
            url: '/v1/QRcode',
            method: 'post',
            params
        })
    }
}

//获取地址栏url后的参数值
const getUrlTool = {
    getUrlParam(key) {
        var href = window.location.href;
        var url = href.split("?");
        if(url.length <= 1){
            return "";
        }
        var params = url[1].split("&");

        for(var i=0; i<params.length; i++){
            var param = params[i].split("=");
            if(key == param[0]){
                return param[1];
            }
        }
    }
}

export default getUrlTool
